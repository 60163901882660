.color-picker-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: #fff;
  border-radius: 4px;
  border: #e1e7ed 1px solid;
  gap: 20px;
}
.color-picker-inner {
  box-shadow: none !important;
}

.theme-colors-wrapper {
  display: flex;
  justify-content: space-between;
}
.theme-color {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  cursor: pointer;
}
.theme-unset {
  display: flex;
  justify-content: center;
  align-items: center;
}
