form {
  margin: 0;
  padding: 0;
}

.comment--list {
  width: 100%;
  height: 100%;
}
.comment--list--filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    margin: 0;
  }
}
.no-comments {
  display: flex;
  justify-content: center;
  align-items: center;
}

.comment--add-coment {
  cursor: pointer;
  margin-left: 10px;
  padding: 12px;
  border-radius: 50%;
  background: #e0eff9;
  color: #547ba2;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.comment {
  border: solid 1px $gray-300;
  border-left-width: 0;
  border-right-width: 0;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  &.active {
    background-color: $blue-200;
  }
  &.draft {
    background-color: #fffeec;
  }
  &.archived {
    background-color: $blue-200;
  }
  .comment--header {
    display: flex;
    justify-content: space-between;
    .inner {
      display: flex;
      gap: 8px;
    }
  }

  .comment--header--icon,
  .comment--header--author {
    display: inline-block;
    vertical-align: middle;
  }

  .comment--header--icon {
    border: solid 3px #ffffff;
  }

  .comment--header--author--name {
    color: #252525;
    font-size: 14px;
  }

  .comment--header--author--date {
    color: #878787;
    font-size: 10px;
    margin-top: 2px;
  }

  .comment--header--menu {
    float: right;
    position: relative;
    display: flex;
    width: 30px;
    justify-content: space-between;

    .comment--popmenu {
      position: absolute;
      right: 10px;
      top: 0;
      padding: 21px 43px 20px 21px;
      border: solid 1px #e5e5e5;
      background-color: #ffffff;

      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
      }

      li {
        margin: 0;
        padding: 0;
      }
    }
  }

  .comment-buttons {
    width: 100%;
    display: flex;
    justify-content: end;
    gap: 12px;

    button {
      font-size: 14px;
    }
  }

  .comment--message {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: $gray-900;
  }

  .comment-icon {
    cursor: default;
    width: 24px;
    height: 24px;
    :hover {
      cursor: pointer;
    }
  }

  .comment--response {
    display: flex;
    flex-direction: row;
    height: 50px;
    margin: 15px 0 0;
    border-top: solid 1px $gray-400;
  }
  .mention-wrapper {
    width: 100%;
    min-height: 100px;
    container-type: inline-size;
    input {
      color: black;
    }
    textarea {
      width: 100%;
      resize: none;
      height: 100px;
      color: black;
      padding: 12px;
      border-radius: 5px;
      border: 1px solid $blue-800;

      :focus {
        border: 1px solid red;
      }
    }
  }

  .comment--response-button {
    width: fit-content;
    margin: 0;
    text-decoration: underline;
    :hover {
      cursor: pointer;
    }
  }
  .comment--response--wrapper {
    .comment {
      padding: 16px;
      padding-right: 0px;
      border: none;
    }
  }

  .comment-mention-list {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    max-height: 150px;
    position: fixed;
    text-align: start;
    z-index: 1000;
    width: 100cqw;
    overflow-y: scroll;
  }
  .comments-mention-list-item {
    padding: 8px;
    cursor: pointer;
  }
  .comment-no-mention {
    padding: 8px;
    color: #999;
  }

  .comment--responses .comment {
    background: #ffffff;
    border: none;
    padding: 20px 0;
    border-top: solid 1px #dddddd;
    &:first-child {
      border-top: solid 1px $gray-400;
    }
  }
  .comment--citation {
    font-weight: 400;
    font-size: 11px;
    line-height: 22.5px;
    letter-spacing: 0px;
  }
  .comment--warning {
    color: $red-500;
    font-size: 10px;
  }

  .comment--buttons {
    display: flex;
    justify-content: end;
    flex-direction: row;
    gap: 8px;
  }

  .comment--controls {
    background-color: white;
    display: flex;
    gap: 16px;
    flex-direction: column;
    padding: 24px;
    border: solid 1px $gray-300;
    position: relative;
    .comment--controls--item {
      display: flex;
      align-items: center;
      gap: 16px;
      flex-direction: row;

      p {
        margin-bottom: 0px;
      }
      :hover {
        cursor: pointer;
      }
      &.disabled {
        color: $gray-400;
        cursor: not-allowed;
      }
    }

    .comment--controls--confirm {
      position: absolute;
      top: 0;
      left: 0;
      height: max(100%, 140px);
      width: 100%;
      background: rgba(0, 0, 0, 0.7);
      backdrop-filter: blur(2px);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 8px;
      justify-content: space-around;
      p,
      button {
        color: white;
        font-size: 14px;
        margin: 0;
      }
      .comment--controls--inner {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      .comment--confirm--button {
        border: none;
        border-radius: 4px;
        color: black;
        display: flex;
        padding: 8px 24px;
        align-items: center;
        gap: 16px;
        justify-content: center;

        p {
          color: black;
          margin: 0;
        }
      }
    }
  }

  .comment--mention {
    font-weight: 700;
  }

  .comment--archived {
    display: flex;
    flex-direction: row;
    gap: 8px;
    background-color: #f5f5f5;
    padding: 4px 8px;
  }
  .comment--controls--wrapper {
    height: min-content;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
